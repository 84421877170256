import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setLogout } from "features/authSlice";
import { useNavigate } from "react-router-dom";

import "./header-menu.scss";
import useDesktopChecker from "hooks/useDesktopChecker";
import DropDownMenu from "../DropDownMenu/DropDownMenu";

export interface HeaderMenuProps {
  name?: string;
  onShow?: boolean;
  showMyLoans: boolean;
}

export default function HeaderMenu({ name, onShow, showMyLoans }: Readonly<HeaderMenuProps>) {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    setShowPopUp(!showPopUp);
  };
  const onClickLogout = () => {
    dispatch(setLogout());
    navigate("/");
  };

  const isDesktop = useDesktopChecker();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowPopUp(showPopUp);
        setChecked(checked);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  return (
    <>
      {isDesktop && (
        <div className="header-menu" onClick={handleChange} ref={dropdownRef}>
          <span className="header-menu__description">{name}</span>
          <span className="header-menu__icon">
            {showPopUp ? (
              <FontAwesomeIcon icon={solid("caret-up")} />
            ) : (
              <FontAwesomeIcon icon={solid("caret-down")} />
            )}
          </span>
        </div>
      )}
      {(onShow || showPopUp) && (
        <DropDownMenu onShow={checked || onShow} onClickLogout={onClickLogout} showMyLoans={showMyLoans} />
      )}
    </>
  );
}
