import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getStepData } from "components/question/questionSlice";
import "./navbarDropDown.scss";
import HeaderMenu from "../header-menu/HeaderMenu";
import { useSelector } from "react-redux";
import useDesktopChecker from "hooks/useDesktopChecker";
import { INavBarProps, NavLinks } from "./NavBarUtilities";

export default function NavbarDropDown({
    userName,
    showNavLinks = false,
    showMyLoans
}: Readonly<INavBarProps>) {
    const navRef = useRef<HTMLElement>(null);
    const [showMenu, setShowMenu] = useState(false);

    const showNavbar = () => {
        setShowMenu(!showMenu);
    };
    const isDesktop = useDesktopChecker();
    const stepData = useSelector(getStepData);
    const isSummary = stepData?.stepType === "summary";
    const isMyApp = location.pathname === "/my-apps";
    return (
        <div className="navbar-container">
            {isDesktop ? (
                <nav ref={navRef} className="navbar">
                    {!isSummary && showNavLinks && <NavLinks />}
                    <div className="navbar-right">
                        {
                            !isMyApp && showMyLoans && <>
                                <NavLink to="/my-apps" className="menu-link" end>
                                    My Loans
                                </NavLink>
                                <span className="menu-link-splitter">|</span>
                            </>
                        }

                        {userName && <HeaderMenu name={userName} showMyLoans={showMyLoans ?? false} />}
                        <span onClick={showNavbar} className="navbar__btn navbar__close-btn">
                            <FontAwesomeIcon icon={regular("xmark")} />
                        </span>
                    </div>
                </nav>
            ) : (
                <>
                    {showMenu && (
                        <nav ref={navRef} className="navbar">
                            <HeaderMenu onShow={showMenu} showMyLoans={showMyLoans ?? false} />
                            <span
                                onClick={showNavbar}
                                className="navbar__btn navbar__close-btn"
                            >
                                <FontAwesomeIcon icon={regular("xmark")} />
                            </span>
                        </nav>
                    )}
                </>
            )
            }

            {
                !isDesktop && (
                    <span onClick={showNavbar} className="navbar__btn">
                        <FontAwesomeIcon icon={regular("bars")} />
                    </span>)
            }
        </div >
    );
}
