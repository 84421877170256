import useDesktopChecker from "hooks/useDesktopChecker";
import "./dropDownMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export interface DropDownMenuProps {
    onClickPreference?: () => void;
    onClickLogout?: () => void;
    onShow?: boolean;
    showMyLoans: boolean;
}
const DropDownMenu = ({
    onClickPreference,
    onClickLogout,
    onShow,
    showMyLoans
}: DropDownMenuProps) => {
    const isDesktop = useDesktopChecker();
    const progress = location.pathname === "/application";
    const isMyApp = location.pathname === "/my-apps"; //need to confirm the route
    return (
        <div className="dropDownMenu">
            <div className={`popup ${onShow ? "popup--show" : "popup--hide"}`}>
                <div className="popup__wrapper">
                    {progress && (
                        <div className="popup__wrapper--content">
                            Your progress is automatically saved!
                        </div>
                    )}
                    {
                        !isDesktop && showMyLoans && !isMyApp &&
                        <div className="popup__wrapper__top-buttons">
                            <span
                                className=""
                            >
                                My Loans
                            </span>
                        </div>
                    }
                    {(progress || !isDesktop) && (
                        <div className="popup__wrapper--line"></div>
                    )}
                    <div className="popup__wrapper__buttons">
                        <span
                            className="popup__wrapper__buttons__preferences"
                            onClick={onClickPreference}
                        >
                            Preferences
                        </span>
                        <span
                            className="popup__wrapper__buttons__logout"
                            onClick={onClickLogout}
                        >
                            <span>Logout</span>
                            <FontAwesomeIcon
                                icon={solid("right-from-bracket")}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropDownMenu;
