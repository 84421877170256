import TableItems from "components/Table/Table";
import Card from "components/card/Card";
import {
  useGetDashboardItemsQuery,
  DashboardItemsResponseDto,
  useSetStatusFlagOfUserMutation,
} from "./dashboardService";
import FundedState from "components/funded-state/FundedState";
import LoanProgessBar from "components/ui/loan-progress-bar/LoanProgessBar";
import { useSelector, useDispatch } from "react-redux";
import DashboardPopUp from "pages/dashboard-popup/Dashboard-popup";
import AnyDocumentImg from "assets/images/any_documents_img.png";
import "./dashboard.scss";
import {
  getCurrentUserName,
  getCurrentUserId,
  setLogout,
} from "features/authSlice";
import { useNavigate } from "react-router";
import { showDocuments } from "utilities/UserversionFeatures";
import { useState } from "react";
import NafInsuranceAd from "../advertisements/NafInsurance";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";

export const tooltipIndexValues = {
  initial: 0,
  conditions: 1,
  milestones: 2,
  completed: 3,
};

export default function Dashboard() {
  const userName = useSelector(getCurrentUserName);
  const userId = useSelector(getCurrentUserId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isError, error } = useGetDashboardItemsQuery(userId);
  const [tooltipIndex, setTooltipIndex] = useState(tooltipIndexValues.initial);

  let component: JSX.Element | null = null;
  if (
    isError &&
    typeof error === "object" &&
    "status" in error &&
    error.status === 401
  ) {
    dispatch(setLogout());
    navigate("/", { replace: true, state: { path: location.pathname } });
  }
  if (data == undefined) {
    return null;
  }
  const {
    pendingItems,
    loanStatus: { status, subStatus },
    closingDate,
  }: DashboardItemsResponseDto = data;

  switch (status) {
    case "Processing":
    case "Underwriting":
    case "Approval":
    case "FinalReview":
    case "Close":
      component = <ProcessToCloseStatusContent />;
      break;
    case "Funded":
      component = <FundedState userName={userName.toUpperCase() + "!"} />;
      break;
    default:
      if (pendingItems.length > 0) {
        component = (
          <OutStandingItemsCard
            tooltipIndex={tooltipIndex}
            setTooltipIndex={setTooltipIndex}
          />
        );
      } else {
        component = <ProcessToCloseStatusContent />;
      }
  }

  return (
    <>
      {status != "Funded" && (
        <LoanProgessBar
          status={status}
          currentStatus={subStatus}
          closingDate={closingDate}
          showTooltip={tooltipIndex === tooltipIndexValues.milestones}
          setTooltipIndex={setTooltipIndex}
        />
      )}
      {component}
    </>
  );
}

const OutStandingItemsCard = (props: {
  tooltipIndex: number;
  setTooltipIndex: (index: number) => void;
}) => {
  const { tooltipIndex, setTooltipIndex } = props;
  const userId = useSelector(getCurrentUserId);
  const userName = useSelector(getCurrentUserName);
  const { pendingItems, isUserNew, isGetItemError, getItemError } =
    useGetDashboardItemsQuery(userId, {
      selectFromResult: ({ data, isError, error }) => ({
        pendingItems: data!.pendingItems,
        isUserNew: data!.isNew,
        isGetItemError: isError,
        getItemError: error,
      }),
    });
  const [updateUserstatus, { isError, error }] =
    useSetStatusFlagOfUserMutation();
  const { data: featureFlag } = useGetFeatureFlagsQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (isGetItemError || isError) {
    if (
      typeof getItemError === "object" &&
      "status" in getItemError &&
      getItemError.status === 401
    ) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    if (
      typeof error === "object" &&
      "status" in error &&
      error.status === 401
    ) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    // should add code for other errors
  }
  const handleClose = () => {
    void updateUserstatus(userId);
    setTooltipIndex(tooltipIndexValues.conditions);
  };

  const getConditionsTile = () => {
    return <>
      <TableItems
        Tablevalues={pendingItems}
        showTooltip={tooltipIndex === tooltipIndexValues.conditions}
        setTooltipIndex={setTooltipIndex}
      />

      {featureFlag?.insuranceAdEnabled && <NafInsuranceAd />}
    </>
  }

  return (
    <>
      <div className="conditionsContainer">
        <Card
          header={tooltipIndex === tooltipIndexValues.conditions ? "" : "You can conveniently upload your documents at any time!"}
          body={
            getConditionsTile()
          }
        />
      </div>
      {showDocuments() && (
        <div className="dashboard-content">
          <p className="dashboard-content__text">
            * All documents uploaded by you are visible under the My Documents
            tab
          </p>
        </div>
      )}
      {isUserNew && (
        <DashboardPopUp
          onClose={() => {
            handleClose();
          }}
          userName={userName.split(' ')[0]}
        />
      )}
    </>
  );
};

const ProcessToCloseStatusContent = () => {
  return (
    <div className="any-document">
      {showDocuments() && (
        <p className="any-document__information">
          If you have additional documents to upload, you can simply navigate to
          the <a href="/dashboard/documents">My Documents</a> section.
        </p>
      )}
      <img src={AnyDocumentImg} alt="logo" className="any-document__img" />
    </div>
  );
};
